<template>
  <div>
    <div class="meeting_action" @click="openMeeting">Возобновить митинг?</div>
    <!--<div @click="openMeetingTest">open meeting test</div>-->
    <!--<div @click="closeMeeting">close meeting</div>-->
  </div>
</template>


<script>

import {jitsiShow, jitsiHide, jitsiStartConference, jitsiEndConference} from '@store/jitsi.store';

export default {
  name: 'Meeting',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    openMeeting() {
      jitsiShow();
      jitsiStartConference(this.id);
    },
    // closeMeeting() {
    //   jitsiEndConference();
    // },
    // openMeetingTest() {
    //   jitsiShow();
    //   jitsiStartConference('test-meeting');
    // },
  },
  mounted() {
    jitsiShow();
    jitsiStartConference(this.id);
  },
  unmounted() {
    jitsiHide();
  },
};

</script>

<style lang="scss" scoped>
.meeting_action {
  font-size: 0.875rem;
  margin: 0 0.25rem;
  padding: 5rem 0.5rem;
  color: var(--color-secondary);
  cursor: pointer;
  text-align: center;
}
</style>
